import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '@core/models/user.model';
// import { GraphqlClientService } from '@core/services/graphql-client.service';
import { AuthState } from '@core/store/auth/auth.state';
// import { LoadCountryById, LoadOrgById } from '@core/store/shared/shared.actions';
import { SharedState } from '@core/store/shared/shared.state';
import { environment } from '@env/environment';
import { Select, Store } from '@ngxs/store';
import { ResetWorkflow } from 'app/json-schema-forms/store/workflow.actions';
import { ResetWizard } from 'app/modules/wizard/store/wizard.actions';
import { CookieService } from 'ngx-cookie-service';
import { filter, Observable, tap } from 'rxjs';
import { NavigationConfig } from '../modules/organizations-manager/models/organization.model';
import { NavItem } from './types/nav-item.type';

@Component({
  selector: 'hmt-app-wrapper',
  templateUrl: './app-wrapper.component.html',
  styleUrl: './app-wrapper.component.scss',
})
export class AppWrapperComponent implements OnInit {
  @Select(AuthState.isAuthenticated) isAuthenticated: Observable<boolean>;
  @Select(AuthState.getUser) user: Observable<User>;
  @Select(SharedState.getSpinnerStatus) spinnerProps: Observable<{ spinner: boolean; spinnerMessage: string }>;
  @Select(SharedState.getNavigationConfig) navBarModuleConfigs$: Observable<NavigationConfig[]>;

  title = 'Dwara.One';
  @ViewChild('sidenav') sidenav: MatSidenav;
  expanded = false;
  isShowing = false;
  developer = environment.developer;
  version = environment.version;
  userOrganizationId: string;
  isLoading = false;

  navItems: NavItem[] = [];

  constructor(
    private store: Store,
    private router: Router,
    private cookiesService: CookieService
    // private gqlService: GraphqlClientService
  ) {
    this.userOrganizationId = this.cookiesService.get('orgId');
    // this.gqlService.createLinkWithGQLServer();

    // this.user
    //   .pipe(
    //     filter(u => u != null),
    //     take(1),
    //     mergeMap(user => {
    //       return this.store.dispatch(new LoadOrgById(user.orgId));
    //     }),
    //     mergeMap(_ => {
    //       return this.store.dispatch(new LoadCountryById());
    //     })
    //   )
    //   .subscribe();
  }

  ngOnInit(): void {
    this.processNavItems();
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          if (event.urlAfterRedirects === '/dashboard') {
            this.store.dispatch(new ResetWorkflow());
            this.store.dispatch(new ResetWizard());
          }
        })
      )
      .subscribe();
  }

  processNavItems(): void {
    // TODO: handle access using scopes from here
    this.navItems = [
      { icon: 'assets/icons/home.svg', label: 'HOME', routerLink: '/dashboard', active: true },
      { icon: 'assets/icons/job_master.svg', label: 'JOB MASTER', active: false },
      { icon: 'assets/icons/rate_card_manager.svg', label: 'RATE CARD MANAGER', active: false },
      { icon: 'assets/icons/invoice_manager.svg', label: 'INVOICE MANAGER', active: false },
      {
        icon: 'assets/icons/contract_manager.svg',
        label: 'CONTRACT MANAGER',
        routerLink: '/contract-management',
        active: true,
      },
      { icon: 'assets/icons/reports.svg', label: 'REPORTS', active: false },
      {
        icon: 'assets/icons/org-manager.svg',
        label: 'ORGANIZATIONS MANAGER',
        routerLink: '/organizations-manager/organizations',
        active: true,
      },
      {
        icon: 'assets/icons/org-manager.svg', // TODO: use appropriate icon
        label: 'CATALOG MANAGER',
        routerLink: '/catalog-manager/vessel-schedules',
        active: true,
      },
      { icon: 'assets/icons/tools.svg', label: 'TOOLS', active: false },
    ];
  }

  mouseenter() {
    if (!this.expanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.expanded) {
      this.isShowing = false;
    }
  }

  onToggle() {
    this.expanded = !this.expanded;
  }

  collapseModule(module: NavigationConfig): void {
    this.navigateTo(module);
  }

  navigateTo(module: NavigationConfig): void {
    this.router.navigate([module.navigationUrl]);
  }

  toggleNavBar() {
    this.expanded = !this.expanded;
  }
}
