import { Injectable } from '@angular/core';
import { SharedStateModel } from '@core/models/shared-state.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NavigationConfig } from 'app/modules/organizations-manager/models/organization.model';
import { ResetSharedState, SetSpinner } from './shared.actions';

const defaults: SharedStateModel = {
  spinner: false,
  country: null,
  organization: null,
  spinnerMessage: '',
};

@State<SharedStateModel>({
  name: 'shared',
  defaults,
})
@Injectable()
export class SharedState {
  constructor() {}

  @Selector()
  static getSpinnerStatus(state: SharedStateModel): { spinner: boolean; spinnerMessage: string } {
    return { spinner: state.spinner, spinnerMessage: state.spinnerMessage };
  }

  @Selector()
  static getNavigationConfig(state: SharedStateModel): NavigationConfig[] {
    return state.organization?.orgConfig?.navigationConfig || [];
  }

  @Action(SetSpinner)
  setSpinner({ patchState }: StateContext<SharedStateModel>, { isEnable, message }: SetSpinner) {
    return patchState({ spinner: isEnable, spinnerMessage: message });
  }

  @Action(ResetSharedState)
  resetState({ setState }: StateContext<SharedStateModel>) {
    return setState(defaults);
  }
}
